import React from "react"

export default function () {
  return (
    <section>
      <div>
        <div id="what-can-you-do" className="section about-content">
          <h2>What can you do to ensure that the past is never forgotten?</h2>
          <p>
            Between the 20th and 27th of January – in advance of International
            Holocaust Remembrance Day – you can engage in simple but meaningful
            acts to bring this critical message to your community:
            <ul className="list-disc list-inside">
              <li>
                Use the hashtag #WeRemember on social media and share content
                dedicated to preserving the legacy of the Holocaust.
              </li>
              <li>
                Post your photo of yourself holding a #WeRemember sign to social
                media. Ask others to do the same.
              </li>
              <li>
                Hold an event in your community with featuring the testimony of
                a Holocaust survivor.
              </li>
              <li>
                Illuminate the past and focus your community’s attention through
                a public display of remembrance.
              </li>
            </ul>
          </p>
          <p>
            <a
              href="https://weremember.worldjewishcongress.org/documents/"
              className="underline"
            >
              Click here
            </a>{" "}
            to find materials to share with your community or on social media.
            Additional content will be made available throughout the month of
            January. Images of acts of remembrance will be displayed at the site
            of the former Auschwitz-Birkenau death camp in partnership with the
            Auschwitz Memorial and Museum on the 26th and 27th of January.
          </p>
          <p>
            <strong>Educate the world by taking to social media:</strong>{" "}
            <br></br> Facebook users who searching for keywords associated with
            the Holocaust are encouraged to learn more at WJC and UNESCO’s
            www.aboutholocaust.org. On TikTok, individual posts related to the
            subject contain a notification encouraging viewers to do the same.
          </p>
        </div>
      </div>
    </section>
  )
}
