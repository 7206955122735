import React from "react"
import playIcon from "../../images/we-remember-section/play.svg"
import Img from "gatsby-image"

export default ({ video, thumbnail, onClick, className }) => {
  return (
    <button onClick={() => onClick({ video, thumbnail })} className={`we-remember-video ${className}`}>
      <Img fluid={thumbnail.childImageSharp.fluid} className="-thumbnail" />
      <img src={playIcon} alt="Play the Video" className="-play" />
    </button>
  )
}
