import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

SwiperCore.use([Autoplay, Navigation])

export default () => {
  const {
    allPrismicHighlights: { nodes: highlights },
  } = useStaticQuery(graphql`
    query highlights {
      allPrismicHighlights {
        nodes {
          id
          data {
            image {
              url(imgixParams: { fit: "crop", w: 688, h: 427 })
              alt
            }
            title {
              text
            }
            link {
              url
              target
            }
          }
        }
      }
    }
  `)

  return (
    <section className="highlights-section container my-12">
      <h2 className="mb-6">Highlights</h2>

      <Swiper
        spaceBetween={30}
        autoplay={{ delay: 10000 }}
        breakpoints={{
          0: {
            width: 0,
            slidesPerView: 1,
          },
          640: {
            width: 640,
            slidesPerView: 1,
          },
          768: {
            width: 768,
            slidesPerView: 2,
          },
          1024: {
            width: 1024,
            slidesPerView: 3,
          },
          1280: {
            width: 1280,
            slidesPerView: 3,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        className="highlights-swiper-container"
      >
        {highlights.map(({ id, data }) => (
          <SwiperSlide key={id}>
            <a href={data.link.url} className="group block">
              <img src={data.image.url} alt={data.image.alt} />
              <h4 className="text-almost-black font-semibold text-lg md:text-xl xl:text-2xl capitalize mt-4 group-hover:underline">
                {data.title.text}
              </h4>
            </a>
          </SwiperSlide>
        ))}

        <div className="swiper-button-prev swiper-nav-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.668 3.49592L13.252 2.07992L5.33197 9.99992L13.252 17.9199L14.668 16.5039L8.16397 9.99992L14.668 3.49592H14.668Z"
              fill="#212530"
              fill-opacity="0.5"
            />
          </svg>
        </div>

        <div className="swiper-button-next swiper-nav-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.668 3.49592L13.252 2.07992L5.33197 9.99992L13.252 17.9199L14.668 16.5039L8.16397 9.99992L14.668 3.49592H14.668Z"
              fill="#212530"
              fill-opacity="0.5"
            />
          </svg>
        </div>
      </Swiper>
    </section>
  )
}
