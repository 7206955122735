class Animator {
  constructor(el) {
    this.el = el

    this.targetValue = this.lastValue = this.captureValue()
    this.setValue(this.targetValue)

    const onScroll = () => {
      this.targetValue = this.captureValue()
      this.tick()
    }

    window.addEventListener("scroll", onScroll)
    this.destroy = () => window.removeEventListener("scroll", onScroll)
  }

  destroy() {
    window.removeEventListener("scroll", this.onScroll)
    clearInterval(this.interval)
  }

  tick() {
    const ease = 0.005
    const diff = this.targetValue - this.lastValue
    const delta = Math.abs(diff) < 0.1 ? 0 : diff * ease

    if (delta) {
      this.lastValue = parseFloat((this.lastValue + delta).toFixed(2))
      this.setValue(this.lastValue)
      requestAnimationFrame(() => this.tick())
    } else {
      this.lastValue = this.targetValue
      this.setValue(this.lastValue)
    }
  }

  setValue(val) {
    this.el.style.setProperty("--animation-val", val)
  }

  captureValue() {
    const offset = 0.2
    const slowdown = 2.3
    const { top, height } = this.el.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    const visiblePixels = Math.max(
      0,
      Math.min(
        viewportHeight - top - viewportHeight * offset,
        height * slowdown
      )
    )

    return ((visiblePixels / height) * 100) / slowdown
  }
}

export default el => {
  const animator = new Animator(el)

  return animator.destroy
}
