import React from "react"

import { Helmet } from "react-helmet"

import AboutSection from "../components/about-section/section"
import CookiesNotice from "../components/cookies-notice"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Hero from "../components/hero/section"
import Partners from "../components/partners/section"
import SupportTheCampaignSection from "../components/support-the-campaign/section"
import VideoMasonryGrid from "../components/video-masonry-grid/section"
import WeRememberSection from "../components/we-remember-section/section"
import WhatYouCanDoSection from "../components/what-can-you-do/section"
import WhyMust from "../components/why-must-section/section"
import Highlights from "../components/highlights/section"
import WhatCanYouDo from "../components/what-can-you-do-section/section"
import SingleVideo from "../components/why-must-section/single-video"

export default function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join the #WeRemember Campaign!</title>
        <meta
          content="The campaign for International Holocaust Remembrance Day."
          name="description"
        />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Helmet>

      <Header />
      <Hero />
      <div className="container lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="lg:col-span-8">
          <AboutSection />
          <WeRememberSection />
          <WhyMust />
          <SingleVideo />
          <WhatCanYouDo />
          <VideoMasonryGrid />
          <SupportTheCampaignSection />
        </div>
        <div className="lg:col-span-4 lg:col-start-9">
          <WhatYouCanDoSection />
        </div>
      </div>

      <Highlights />
      <Partners />
      <Footer />
      <CookiesNotice />
    </div>
  )
}
