import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Partners() {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query PartnersImages {
      allFile(filter: { relativeDirectory: { eq: "partners" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 166) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const unesco = nodes.find(({ name }) => name === "unesco")
  const eujs = nodes.find(({ name }) => name === "eujs")
  const rjc = nodes.find(({ name }) => name === "rjc")
  const cjl = nodes.find(({ name }) => name === "cjl")
  const wujs = nodes.find(({ name }) => name === "wujs")
  const ab = nodes.find(({ name }) => name === "ab")
  const abmf = nodes.find(({ name }) => name === "abmf")
  const joc = nodes.find(({ name }) => name === "joc")

  return (
    <section className="partners-section">
      <div className="container">
        <h3 className="partners-title">Partners</h3>
        <div className="partners-content">
          <a href="https://eujs.org/" target="_blank" rel="nofollow noreferrer">
            <Img
              fluid={eujs.childImageSharp.fluid}
              alt="European Union of Jewish Students"
            />
          </a>
          <a href="https://rjc.ru/" target="_blank" rel="nofollow noreferrer">
            <Img
              fluid={rjc.childImageSharp.fluid}
              alt="Russian Jewish Congress"
            />
          </a>
          <a
            href="https://congresojudio.org/"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <Img
              fluid={cjl.childImageSharp.fluid}
              alt="El Congreso Judío Latinoamericano"
            />
          </a>
          <a
            href="https://wujs.org.il/"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <Img
              fluid={wujs.childImageSharp.fluid}
              alt="The World Union of Jewish Students"
            />
          </a>

          <a
            href="http://auschwitz.org/"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <Img
              fluid={ab.childImageSharp.fluid}
              alt="Auschwitz Miejsce Pamięci Białe"
            />
          </a>

          <a
            href="https://www.preserveauschwitz.org/"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <Img
              fluid={abmf.childImageSharp.fluid}
              alt="Auschwitz-Birkenau Memorial Foundation"
            />
          </a>

          <a
            href="https://www.jewishoncampus.org/"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <Img
              fluid={joc.childImageSharp.fluid}
              alt="Jewish On Campus"
            />
          </a>
        </div>
      </div>
    </section>
  )
}
