import React from "react"
import Video from "./video"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query MasonryVideosImages {
      allFile(filter: { relativeDirectory: { eq: "masonry-videos" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 384) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const thumbnail = name => nodes.find(item => item.name === name)
  const renderVideo = ({ youtubeId, title, className = "" }) => (
    <Video
      youtubeId={youtubeId}
      title={title}
      className={className}
      thumbnail={thumbnail(youtubeId)}
    />
  )

  return (
    <section id="stories" className="section video-masonry-grid-section">
      <h2>
        Stories from the Holocaust
      </h2>
      <div className="mt-8">
        <div className="video-masonry-grid-grid">
          <div className="video-masonry-grid-col">
            {renderVideo({
              youtubeId: "Juc32C80W7A",
              title:
                "Speaking to us from the past: Colorising photos from the Holocaust",
            })}
             {renderVideo({
              youtubeId: "00De6tb8SPs",
              title:
                "Beyond the shadows: Judy Glickman Lauder’s haunting photos from Auschwitz-Birkenau",
              className: "-small",
            })}
            {renderVideo({
              youtubeId: "UloLcL--O7M",
              title:
                "The pink triangle prisoners: The Nazis’ persecution of homosexual men",
            })}
          </div>
          <div className="video-masonry-grid-col">
          {renderVideo({
              youtubeId: "k8qVQjemqNw",
              title: "A forgotten tragedy: The Shoah in North Africa",
              className: "-small",
            })}
            {renderVideo({
              youtubeId: "db-LMOoZaIQ",
              title:
                "The rediscovered paintings of a Jewish artist murdered in the Holocaust",
            })}
            {renderVideo({
              youtubeId: "1j9Ti9dAe5c",
              title: "The Romani Genocide",
              className: "-small",
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
