import React, { useEffect, useState, createRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import playIcon from "../../images/we-remember-section/play.svg"
import animate from "../../js/animate"
import Video from "../we-remember-section/video"

export default function SingleVideo() {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query WeRememberImage {
      allFile(filter: { relativeDirectory: { eq: "we-remember-section" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 950) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const thumbnail = name => nodes.find(item => item.name === name)
  const videos = [
    {
      video:
        "https://wjc-org-website.s3.amazonaws.com/horizon/assets/eGAVkaZG/201224_weremember-youtube-1080p.mp4",
        thumbnail: thumbnail("video1"),
    },

  ]
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(videos[0])

  const sectionRef = createRef()
  const currentVideoRef = createRef()

  const selectVideo = ({ video, thumbnail }) => {
    setIsVideoVisible(false)
    setCurrentVideo({ video, thumbnail })
  }

  useEffect(() => {
    if (currentVideoRef.current) {
      currentVideoRef.current.play()
    }
  }, [currentVideoRef])
  useEffect(() => animate(sectionRef.current), [sectionRef])

  return (
    <section ref={sectionRef} className="we-remember">
      <div className="">
        <div
          className={`video-container ${
            isVideoVisible ? "-video" : "-thumbnail"
          }`}
        >
          {isVideoVisible && (
            <div className="embedded-video">
              <video
                ref={currentVideoRef}
                controls={true}
                key={currentVideo.video}
              >
                <source src={currentVideo.video} type="video/mp4" />
              </video>
            </div>
          )}
          {!isVideoVisible && (
            <button className="w-full" onClick={() => setIsVideoVisible(true)}>
              <Img
                fluid={currentVideo.thumbnail.childImageSharp.fluid}
                className="video-container-thumbnail"
                alt="WeRemember video"
              />
              <img
                src={playIcon}
                className="video-container-play"
                alt="Play the Video"
              />
            </button>
          )}
        </div>
        <div className="we-remember-videos hidden">
          {videos.map(video => (
            <Video
              key={video.video}
              video={video.video}
              thumbnail={video.thumbnail}
              onClick={payload => selectVideo(video)}
              className={currentVideo.video === video.video ? "selected" : ""}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
