import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Item({
  className = "",
  image,
  alt,
  children,
  link = null,
}) {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query Images {
      allFile(filter: { relativeDirectory: { eq: "support-the-campaign" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const desktop = nodes.find(({ name }) => name === image)
  const mobile = nodes.find(({ name }) => name === `${image}-mobile`)

  const images = (
    <Fragment>
      {mobile && (
        <Img
          fluid={mobile.childImageSharp.fluid}
          className="block xs:hidden"
          alt={alt}
        />
      )}

      <Img
        fluid={desktop.childImageSharp.fluid}
        className={`${mobile && "hidden xs:block"}`}
        alt={alt}
      />
    </Fragment>
  )

  const content = link ? (
    <a href={link} target="_blank" rel="nofollow noreferrer">
      {images}
    </a>
  ) : (
    images
  )

  return (
    <div className={`support-the-campaign-item ${className}`}>
      {content}
      <h3 className="support-the-campaign-item-title">{children}</h3>
    </div>
  )
}
