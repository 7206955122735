import React, { useState } from "react"
import playIcon from "../../images/we-remember-section/play.svg"
import Img from "gatsby-image"

export default ({ youtubeId, thumbnail, title = "", className = "" }) => {
  const [isVideoVisible, setIsVideoVisible] = useState(false)

  return (
    <div
      className={`video-container ${
        isVideoVisible ? "-video" : "-thumbnail"
      } ${className}`}
    >
      {isVideoVisible && (
        <div className="video-wrapper">
          <iframe
            title="WeRemember video"
            src={`https://www.youtube.com/embed/${youtubeId}?feature=oembed&autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
      {!isVideoVisible && (
        <button className="w-full" onClick={() => setIsVideoVisible(true)}>
          <Img
            fluid={thumbnail.childImageSharp.fluid}
            alt={title}
            className="video-container-thumbnail"
          />

          <img
            src={playIcon}
            className="video-container-play"
            alt="Play the Video"
          />
        </button>
      )}
    </div>
  )
}
