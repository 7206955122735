import React from "react"

export default () => {
  return (
    <section>
      <div>
        <div id="why-must" className="section about-content mb-0">
          <h2>
            <span>Why Must </span>
            <span className="bg-yellow">#WeRemember</span>
            <span> The Holocaust?</span>
          </h2>
          <p>
            The Holocaust was the organized persecution and killing of 6 million
            Jewish people, alongside other targeted groups, by the Nazis and
            their collaborators during World War II.
            <span>
              {" "}
              <a href="https://aboutholocaust.org/en" className="underline">
                Learning about this history,
              </a>{" "}
            </span>
            and understanding what led to the greatest tragedy in modern times,
            can help prevent future genocides and acts of hatred based on
            religion, ethnicity, or other differences.
          </p>
          <p>
            With the number of survivors growing fewer with each passing year,
            it our shared responsibility to preserve the memory of the victims
            and ensure that the past is never forgotten.
          </p>
        </div>
      </div>
    </section>
  )
}
