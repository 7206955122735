import React from "react"

export default function About() {
  return (
    <section>
      <div>
        <div id="about" className="section about-content">
          <h2>
            The <span className="bg-yellow">#WeRemember</span> Campaign for
            International Holocaust Remembrance Day
          </h2>
          <p>
            We are dedicated to keeping the memory of the Holocaust alive. Join
            us and millions across the globe by raising your voice and shining a
            light on the dangers of failing to learn from history.
          </p>
          <p>
            This last year was a difficult one for the Jewish people and other
            vulnerable communities around the world. Rising antisemitism and the
            continued spread of Holocaust denial online have led to new threats
            that demand urgent action.
          </p>
          <p>
            Encouraging society to reflect on the lessons of the past can ensure
            that future generations develop the knowledge and skills to
            effectively confront these challenges. Ahead of International
            Holocaust Remembrance Day (27 January) take part in the{" "}
            <a
              className="underline"
              href="https://aboutholocaust.org/weremember"
            >
              #WeRemember Campaign
            </a>{" "}
            to remind the world of what can happen when hatred is allowed to
            thrive unchecked.
          </p>
        </div>
      </div>
    </section>
  )
}
