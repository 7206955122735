import React from "react"
import Item from "./item"

export default function Section() {
  return (
    <section id="support-campaign" className="section support-the-campaign">
      <div>
        <h2 className="support-the-campaign-title">
          How else can you support the campaign and honor the victims of the
          Holocaust?
        </h2>
        <div className="support-the-campaign-items">
          <div className="support-the-campaign-items-col">
            <Item image="weremember" alt="#WeRemember hashtag">
              Use the hashtag #WeRemember on social media
            </Item>
            <Item image="online-meeting" alt="Virtual meeting">
              Hold a virtual or an in-person event with social distancing
            </Item>
          </div>
          <div className="support-the-campaign-items-col">
            <Item image="candle" alt="Candle">
              Light a candle in remembrance
            </Item>
            <Item image="holocaust-memorial" alt="Holocaust memorial">
              Visit a local Holocaust memorial or museum
            </Item>
            <Item image="davids-stars" alt="David's stars">
              Create an art project
            </Item>
          </div>
          <div className="support-the-campaign-items-col xs:col-span-2">
            <Item
              image="about-holocaust"
              alt="aboutholocaust.org website"
              className="xs:col-span-2"
              link="https://aboutholocaust.org/"
            >
              Educate yourself about the facts at{" "}
              <a
                href="https://aboutholocaust.org/"
                rel="nofollow noreferrer"
                target="_blank"
              >
                aboutholocaust.org
              </a>
            </Item>
            <div className="flex flex-col space-y-6 xs:gap-8 xs:space-y-0 xs:grid xs:grid-cols-2">
              <Item
                image="share"
                alt="Social post"
                link="https://www.facebook.com/sharer/sharer.php?u=https://aboutholocaust.org/en/"
              >
                Share this history with people you know on social media –{" "}
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://aboutholocaust.org/en/"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
                {" / "}
                <a
                  href="http://twitter.com/share?url=https://aboutholocaust.org/en/"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  Twitter
                </a>
              </Item>
              <Item
                image="survivor"
                alt="Survivor"
                link="https://aboutholocaust.org/en/testimonies"
              >
                Share a survivor's story{" "}
                <a
                  href="https://aboutholocaust.org/en/testimonies"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  click here
                </a>
              </Item>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
