import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const [isSectionVisible, setIsSectionVisible] = useState("")

  useEffect(() => {
    const sections = document.querySelectorAll(".section")

    window.onscroll = () => {
      const scrollPos =
        document.documentElement.scrollTop + 50 || document.body.scrollTop + 50

      for (let s in sections) {
        if (sections.hasOwnProperty(s) && sections[s].offsetTop <= scrollPos) {
          const id = sections[s].id
          setIsSectionVisible(id)
        }
      }
    }
  })

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "what-you-can-do/we-remember.png" }) {
        childImageSharp {
          fluid(maxWidth: 620) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="lg:sticky lg:top-12 mb-12 lg:mb-0">
      <div className="mt-12 hidden lg:block">
        <h4 className="text-black capitalize font-bold text-lg">Contents</h4>
        <div className="mt-4 border-l border-almost-black border-opacity-30 pl-4">
          <a
            href="#about"
            className={`content-links ${
              isSectionVisible === "about" ? "-active-link" : ""
            }`}
          >
            About The #WeRemember Campaign
          </a>
          <a
            href="#why-must"
            className={`content-links ${
              isSectionVisible === "why-must" ? "-active-link" : ""
            }`}
          >
            Why Must #WeRemember The Holocaust?
          </a>
          <a
            href="#what-can-you-do"
            className={`content-links ${
              isSectionVisible === "what-can-you-do" ? "-active-link" : ""
            }`}
          >
            What can you do to ensure that the lessons of the Holocaust are not forgotten?
          </a>
          <a
            href="#stories"
            className={`content-links ${
              isSectionVisible === "stories" ? "-active-link" : ""
            }`}
          >
            Stories from the Holocaust
          </a>
          <a
            href="#support-campaign"
            className={`content-links ${
              isSectionVisible === "support-campaign" ? "-active-link" : ""
            }`}
          >
            How else can you support the campaign and honor the victims of the
            Holocaust?
          </a>
        </div>
      </div>
      <div className="what-you-can-do-section">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="what-you-can-do-image lg:hidden"
          alt="WeRemember"
        />
        <div className="what-you-can-do-content">
          <h2>
            What can you do to ensure that the lessons of the Holocaust are not
            forgotten?
          </h2>
          <ol>
            <li>
              <strong>Write</strong> “We Remember” on a sheet of paper
            </li>
            <li>
              <strong>Take a photo</strong> holding your sign
            </li>
            <li>
              <strong>Share</strong> your photo on social media using
              #WeRemember
            </li>
          </ol>
        </div>
      </div>
    </section>
  )
}
